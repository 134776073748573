<template>
  <ion-page class="ion-page" main>
    <ion-header>
      <ion-card color="white" class="secure-chat-header">
        <div class="avatar current-patient-avatar">
          <img class="prime-main-menu-logomark" src="../assets/MyPCP-logo-mark.svg" />
        </div>
        <h5>LumerisEngage</h5>
        <div class="secure-chat-header-right-icons">
          <button @click="logOut" class="prime-button button-secondary">
            Logout
            <ion-icon name="md-log-out"></ion-icon>
          </button>
        </div>
      </ion-card>
    </ion-header>
    <div
      id="message-panel-content"
      class="message-panel-content column-stretch-body prime-patient-secure-chat-message-panel"
      ref="messagePanel"
      v-chat-scroll="{
        always: false,
        smooth: false,
        scrollonremoved: true
      }"
    >
      <div class="messages-wrapper">
        <div v-if="loading" class="text-center pad-ten loading-overlay center-center">
          <ion-spinner name="lines" class="spinner-xlarge" color="dark"></ion-spinner>
        </div>
        <ChatBubble v-for="(message, key) in sortedMessages" :key="key" v-bind="{ message }" />
      </div>
    </div>

    <ion-footer class="column-stretch-footer prime-conv-message-entry-container secure-chat-footer" mode="ios">
      <MessageInputZeus
        v-if="!inSystemInteraction"
        v-bind="{
          interactionId,
          copy: pageCopy.messageInput
        }"
      />
      <div v-if="messages.count == 0">
        There are no messages to display at this time
      </div>
      <div class="full-width text-center" v-if="inSystemInteraction">
        <div>
          <small>{{ pageCopy.notifications.systemMessageForZeus }}</small>
        </div>
      </div>
    </ion-footer>
  </ion-page>
</template>
<script>
import ChatBubble from "@/components/Chat/ChatBubble";
import MessageInputZeus from "@/components/Chat/MessageInputZeus";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import { addIcons } from "ionicons";
import { logOut } from "ionicons/icons";
import store from "@/store";

addIcons({
  "md-log-out": logOut.md
});

export default {
  name: "SecureChatPatient",
  components: {
    ChatBubble,
    MessageInputZeus
  },
  data() {
    return {
      interactionId: 0,
      patientEvent: document.config.events.beneficiary,
      messageEvent: document.config.events.interaction,
      pageCopy: this.$languagePack.messagePanel,
      messages: [],
      inSystemInteraction: false,
      pageNumber: 1,
      messagesPerPage: 15,
      scrollHeight: 0,
      scrollListening: false,
      loading: false,
      beginningOfChatTxt: {
        body: "Beginning of Chat",
        channel: "sms",
        direction: "none",
        id: null
      }
    };
  },
  computed: {
    sortedMessages() {
      // sort the messages array by date
      return [...this.messages].sort((a, b) => a.id - b.id);
    }
  },
  async mounted() {
    this.getMessages();
    this.initializeMessageInfiniteScroll();
    const claims = await this.$authState.claims();
    const subscription = { type: "beneficiary", data: claims.beneficiaryId };
    await store.dispatch("websocket/subscribe", subscription);

    // when input is sent, start from scratch on paginating/scrolling
    EventBus.$on(this.messageEvent, () => {
      this.pageNumber = 1;
      if (this.scrollListening === false) {
        this.getMessages(true);
        this.initializeMessageInfiniteScroll();
      }
    });

    // on new message triggered by websocket
    EventBus.$on(this.patientEvent, () => {
      this.pageNumber = 1;
      this.getMessages(true);
      this.initializeMessageInfiniteScroll();
    });
  },
  updated() {
    this.updateMessagePanelScrollBarLocation();
  },
  methods: {
    updateMessagePanelScrollBarLocation() {
      const messagePanel = this.$refs.messagePanel;
      messagePanel.scrollTop = this.$refs.messagePanel.scrollHeight - this.scrollHeight;
    },
    initializeMessageInfiniteScroll() {
      const messagePanel = this.$refs.messagePanel;

      messagePanel.addEventListener("scroll", this.scrollListener);
      this.scrollListening = true;
    },
    scrollListener(event) {
      if (this.scrollListening && event.target.scrollTop === 0) {
        this.scrollHeight = event.target.scrollHeight;
        this.pageNumber++;
        this.getMessages();
      }
    },
    logOut() {
      this.$mypcpRouter.push({ path: "/logout" });
    },
    getMessages: function(shouldResetMessages) {
      const method = "get";
      const path = document.config.patientMessagesAndStatus + "?page=" + this.pageNumber + "&perPage=" + this.messagesPerPage;
      this.loading = true;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.updateMessages(result.data.messages, shouldResetMessages);
          this.inSystemInteraction = result.data.inSystemInteraction;
          this.loading = false;
        })
        .catch(() => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not get messages. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
        });
    },
    updateMessages(messages, shouldResetMessages) {
      // concat arrays and reduce to remove duplicates
      if (shouldResetMessages) {
        this.messages = [];
      }
      this.messages = [...this.messages, ...messages].reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      // check to see if we are at the end of the available messages
      if (messages.length < this.messagesPerPage) {
        this.messages.push(this.beginningOfChatTxt);
        this.$refs.messagePanel.removeEventListener("scroll", this.scrollListener);
        this.scrollListening = false;
      }
    }
  }
};
</script>

<style scoped>
.messages-wrapper {
  max-width: 750px;
  margin: 0 auto;
  margin-bottom: 5px;
}
.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}
.column-stretch-header {
  flex: 0 0 auto;
}
.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}
.column-stretch-footer {
  flex: 0 0 auto;
}
ion-card ion-card {
  padding: 0;
  border-radius: 6px !important;
  margin-left: -10px;
  margin-right: -10px;
}
ion-card ion-card:first-child {
  margin-top: 5px;
}
ion-card-content.prime-minimal-list {
  padding: 0 0 0 10px;
  margin-right: -10px;
}
.prime-patient-view ion-row.prime-full-height-row {
  height: calc(100vh - 165px) !important;
}
.prime-patient-view ion-row ion-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.prime-patient-view ion-col ion-card {
  margin-bottom: 0;
}
.prime-patient-view ion-col ion-card:last-child {
  margin-bottom: 0;
}
.prime-patient-view ion-card {
  flex-shrink: 0;
}
.prime-patient-view ion-card.scroll-shrink {
  flex-shrink: 1;
  flex-grow: 1;
}
.prime-patient-view ion-card-header {
  padding: 10px 10px 10px 20px;
}
.prime-internal-scoll-card ion-card-content {
  overflow: auto;
}
.prime-internal-scoll-card {
  display: flex;
  flex-direction: column;
}
.overflow-y-scroll {
  overflow-y: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.prime-patient-data-feed-card ion-card ion-card-title {
  font-size: 16px;
  font-weight: 600;
}
.prime-patient-data-feed-card ion-card ion-card-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium) !important;
  margin-top: 0;
}
.prime-patient-data-feed-card ion-card ion-card-header {
  padding-bottom: 0;
}
/* Right-Hand Accordion */
.prime-rh-accordion ion-card-header {
  display: flex;
  cursor: pointer;
}
.prime-rh-accordion ion-card-title {
  flex-grow: 1;
}
.prime-rh-accordion ion-card-header .ion-card-toggle-controls {
  font-size: 20px;
  margin-bottom: -5px;
  color: var(--ion-color-medium);
}
.prime-rh-accordion ion-card-header .ion-card-toggle-controls ion-icon.closed {
  margin-right: -20px;
}

/* messages column */
.messages-col-inner {
  padding: 0 40px;
}
.message-panel-content {
  padding-top: 8px;
}
.messages-wrapper {
  padding: 10px 20px;
}
.send-message-button {
  color: rgb(0, 98, 255);
}
.message-text-input {
  width: 100%;
  background-color: transparent;
  outline: none;
}
.message-active-badge {
  background-color: var(--ion-color-primary);
  height: 8px;
  width: 8px;
}
@media screen and (max-width: 500px) {
  .sc-ion-card-md-h {
    margin: 0px !important;
    margin-top: 10px !important;
  }
}

/* end messages column */
.prime-conv-message-entry-container {
  padding: 0px 0px;
}
.prime-conv-message-entry-container form.pad-lr-ten {
  padding: 0px;
}
.footer-ios ion-toolbar:first-child {
  --border-width: 0;
}
ion-card.secure-chat-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 10px 0px !important;
  padding: 15px;
}
.secure-chat-header .avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  flex-grow: 0;
}
.secure-chat-header h5 {
  margin: 0;
  flex-grow: 1;
}
.secure-chat-header .secure-chat-header-right-icons {
  flex-grow: 0;
  display: flex;
}

.secure-chat-header .secure-chat-header-right-icons button {
  padding-left: 15px;
  padding-right: 12px;
}
.active-conversation-title-group h6 {
  margin: 0 !important;
}
.active-conversation-options-group {
  width: 100%;
  text-align: right;
  margin-right: 20px;
}
.zeus-header-wrap {
  background-color: white;
}
.zeus-header {
  max-width: 700px;
}
.prime-main-menu-logomark {
  width: 37px;
  background-color: #ebebeb;
  border-radius: 100px;
  padding: 7px;
}

/* Patient Secure Chat message style overrides */
.prime-patient-secure-chat-message-panel .message-balloon,
.prime-patient-secure-chat-message-panel .message-balloon.incoming,
.prime-patient-secure-chat-message-panel .message-balloon.outgoing {
  font-size: 16px;
  padding: 12px 15px;
}
.prime-message-input-container {
  margin-bottom: 5px;
}
.secure-chat-footer form {
  margin-top: 0 !important;
}
.secure-chat-footer .prime-message-input-container {
  border-radius: 0;
  margin-bottom: 0;
  box-shadow: 0 -4 6 rgba(46, 61, 73, 0.1);
}
</style>
