<template>
  <form @submit.prevent="sendMessage" novalidate="true" class="pad-lr-ten margin-top-ten">
    <div class="prime-message-input-container" :class="{ errorPulse: errorPulse }">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <textarea-autosize :placeholder="copy.placeholder" class="form-control prime-message-input" ref="outboundMessageInput" rows="1" v-model="outboundMessage" :min-height="40" :max-height="350" @keydown.native.enter.exact.prevent @keyup.native.enter.exact="sendMessage" @keydown.native.enter.shift.exact="newline" />
          </ion-col>
          <ion-col size="auto">
            <MessageInputTools
              v-bind="{
                msgChannel,
                beneficiaryId
              }"
            ></MessageInputTools>
          </ion-col>
          <ion-col size="auto">
            <button
              type="submit"
              class="prime-button send-message-button"
              :class="{
                'button-pending': isMessageSending,
                secure: msgChannel == 'zeus'
              }"
            >
              <span>
                <span v-if="isSecureChat">Send Securely</span>
                <ion-icon name="arrow-up" size="large" color="primary" mode="md"></ion-icon>
              </span>
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </form>
</template>
<script>
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import { addIcons } from "ionicons";
import { arrowUp } from "ionicons/icons";
import MessageInputTools from "@/components/Chat/MessageInputTools";

addIcons({
  "md-arrow-up": arrowUp.md
});

export default {
  name: "MessageInput",
  components: {
    MessageInputTools
  },
  data() {
    return {
      isMessageSending: false,
      outboundMessage: "",
      errorPulse: false
    };
  },
  props: {
    interactionId: Number,
    beneficiaryId: Number,
    accountId: Number,
    copy: Object,
    msgChannel: String
  },
  computed: {
    isSecureChat() {
      return this.msgChannel === "zeus";
    },
    dbuggaMode() {
      return this.$store.getters["dbugga/dbuggaMode"];
    }
  },
  methods: {
    sendMessage: async function() {
      // dont do anything if no message is there to send.
      if (!this.outboundMessage || this.isMessageSending) {
        this.errorPulse = true;
        setTimeout(() => (this.errorPulse = false), 1000);
        return;
      }

      this.isMessageSending = true;
      const method = "POST";
      const path = document.config.messageUpdateApi[this.userType];
      const payload = await this.getNewMessagePayload(this.userType);

      try {
        await httpSend({
          path,
          method,
          payload,
          authToken: true,
          headers: {
            "Content-Type": "application/json;charset=utf-8"
          }
        });

        this.outboundMessage = "";
        this.isMessageSending = false;

        // raise event
      } catch (error) {
        khanSolo.log(error);
        this.outboundMessage = "";
        this.isMessageSending = false;
      } finally {
        EventBus.$emit(document.config.events.interaction, this.interactionId);
      }
    },
    newline() {
      this.value = `${this.value}\n`;
    },
    handleNewInput(input) {
      this.outboundMessage += input;
    },
    async getNewMessagePayload(role) {
      const claims = await this.$authState.claims();
      const payload = {
        professional: {
          interaction_id: this.interactionId,
          subject: null,
          body: this.outboundMessage,
          created_at: Math.floor(new Date().getTime()),
          urgency: "medium",
          initiator: "conversation",
          status: "pending",
          professional_id: claims.professionalId || 0,
          direction: "outgoing",
          channel: this.msgChannel,
          source: "professional",
          account_id: this.accountId,
          external_correlation_id: 1,
          provider: this.isDbugga && this.dbuggaMode ? "dbugga" : ""
        },
        patient: {
          message: this.outboundMessage
        }
      };

      return payload[role];
    }
  },
  created() {
    EventBus.$on("addToMessageInput", this.handleNewInput);
  }
};
</script>

<style scoped>
.zeus-icon {
  padding-left: 10px;
  fill: white;
}
.prime-message-input-container {
  /* position:relative; */
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2);
  padding: 10px;
}
.form-control.prime-message-input {
  background: #ffffff;
  border: 2px solid #ffffff;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 10px 5px 10px 5px;
  appearance: none;
}
.prime-message-input-container .send-message-button {
  width: 65px;
  /* display: block; */
  /* position: absolute; */
  /* right: 10px; */
  /* bottom: 10px; */
  padding-left: 0;
  padding-right: 0;
  transition: width 0s;
}

.prime-message-input-container .send-message-button.secure {
  font-size: 12px;
  width: 140px;
}
.prime-message-input-container .send-message-button ion-icon {
  margin-right: 0;
}
.prime-message-input-container .send-message-button ion-icon:only-child {
  margin-left: 0;
}
@keyframes border-pulsate {
  0% {
    background-color: rgba(0, 255, 255, 0);
  }
  50% {
    background-color: rgba(128, 255, 0, 0.5);
  }
  100% {
    background-color: rgba(0, 255, 255, 0);
  }
}
.errorPulse {
  animation: border-pulsate 1s 1;
}
</style>