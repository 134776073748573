var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"pad-lr-ten margin-top-ten",attrs:{"novalidate":"true"},on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('div',{staticClass:"prime-message-input-container",class:{ errorPulse: _vm.errorPulse }},[_c('ion-grid',[_c('ion-row',{staticClass:"ion-align-items-center"},[_c('ion-col',[_c('textarea-autosize',{ref:"outboundMessageInput",staticClass:"form-control prime-message-input",attrs:{"placeholder":_vm.copy.placeholder,"rows":"1","min-height":40,"max-height":350},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;return _vm.newline.apply(null, arguments)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.sendMessage.apply(null, arguments)}},model:{value:(_vm.outboundMessage),callback:function ($$v) {_vm.outboundMessage=$$v},expression:"outboundMessage"}})],1),_c('ion-col',{attrs:{"size":"auto"}},[_c('MessageInputTools',_vm._b({},'MessageInputTools',{
              msgChannel: _vm.msgChannel,
              beneficiaryId: _vm.beneficiaryId
            },false))],1),_c('ion-col',{attrs:{"size":"auto"}},[_c('button',{staticClass:"prime-button send-message-button",class:{
              'button-pending': _vm.isMessageSending,
              secure: _vm.msgChannel == 'zeus'
            },attrs:{"type":"submit"}},[_c('span',[(_vm.isSecureChat)?_c('span',[_vm._v("Send Securely")]):_vm._e(),_c('ion-icon',{attrs:{"name":"arrow-up","size":"large","color":"primary","mode":"md"}})],1),_c('ion-spinner',{attrs:{"name":"dots","duration":"1200","color":"white"}})],1)])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }