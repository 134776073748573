var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ion-page',{staticClass:"ion-page",attrs:{"main":""}},[_c('ion-header',[_c('ion-card',{staticClass:"secure-chat-header",attrs:{"color":"white"}},[_c('div',{staticClass:"avatar current-patient-avatar"},[_c('img',{staticClass:"prime-main-menu-logomark",attrs:{"src":require("../assets/MyPCP-logo-mark.svg")}})]),_c('h5',[_vm._v("LumerisEngage")]),_c('div',{staticClass:"secure-chat-header-right-icons"},[_c('button',{staticClass:"prime-button button-secondary",on:{"click":_vm.logOut}},[_vm._v(" Logout "),_c('ion-icon',{attrs:{"name":"md-log-out"}})],1)])])],1),_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({
      always: false,
      smooth: false,
      scrollonremoved: true
    }),expression:"{\n      always: false,\n      smooth: false,\n      scrollonremoved: true\n    }"}],ref:"messagePanel",staticClass:"message-panel-content column-stretch-body prime-patient-secure-chat-message-panel",attrs:{"id":"message-panel-content"}},[_c('div',{staticClass:"messages-wrapper"},[(_vm.loading)?_c('div',{staticClass:"text-center pad-ten loading-overlay center-center"},[_c('ion-spinner',{staticClass:"spinner-xlarge",attrs:{"name":"lines","color":"dark"}})],1):_vm._e(),_vm._l((_vm.sortedMessages),function(message,key){return _c('ChatBubble',_vm._b({key:key},'ChatBubble',{ message },false))})],2)]),_c('ion-footer',{staticClass:"column-stretch-footer prime-conv-message-entry-container secure-chat-footer",attrs:{"mode":"ios"}},[(!_vm.inSystemInteraction)?_c('MessageInputZeus',_vm._b({},'MessageInputZeus',{
        interactionId: _vm.interactionId,
        copy: _vm.pageCopy.messageInput
      },false)):_vm._e(),(_vm.messages.count == 0)?_c('div',[_vm._v(" There are no messages to display at this time ")]):_vm._e(),(_vm.inSystemInteraction)?_c('div',{staticClass:"full-width text-center"},[_c('div',[_c('small',[_vm._v(_vm._s(_vm.pageCopy.notifications.systemMessageForZeus))])])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }